import config from '../../configuration.json';
import axios from 'axios';

const url = `${config.VUE_APP_API_URL}/settings`;

axios.defaults.withCredentials = true;

class SettingsService {
  static async get() {
    const res = await axios.get(`${url}/default`);
    return res.data;
  }

  static async getDeliveryDays() {
    const res = await axios.get(`${url}/deliveryDays`);
    return res.data;
  }

  static async update(data) {
    const res = await axios.put(`${url}/default`, data);
    return res.data;
  }
}

export default SettingsService;
