export const vatMultipliers = Object.freeze({
  VAT8: {
    value: 1.08,
    label: '8%',
  },
  VAT23: {
    value: 1.23,
    label: '23%',
  },
});
